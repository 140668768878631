import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { PricingServices } from 'services';
import { useTranslation } from 'react-i18next';

const MySwal = withReactContent(Swal);

const PackageEdit = () => {
  const [packages, setPackages] = useState([]);
  const { t, i18n } = useTranslation();
  const loadPackages = () => {
    PricingServices.getAllPackages(false).then((res) => {
      if (res) {
        const processedPackages = res.data.map(pkg => ({
          ...pkg,
          useWhiteboard: pkg.useWhiteboard ?? false,
          useNotes: pkg.useNotes ?? false,
          useScreenShare: pkg.useScreenShare ?? false,
          useRecurringMeeting: pkg.useRecurringMeeting ?? false,
          useGeneralChat: pkg.useGeneralChat ?? false,
        }));
        setPackages(processedPackages);
      }
    });
  };

  useEffect(() => {
    loadPackages();
  }, []);

  const handleChange = (id, field, value) => {
    setPackages((prev) =>
      prev.map((pkg) =>
        pkg.id === id ? { ...pkg, [field]: value } : pkg,
      ),
    );
  };

  const handleUpdate = (pkg) => {
    const updatedPkg = {
      ...pkg,
      price: parseFloat(pkg.price).toFixed(2)
    };

    PricingServices.updatePackage(updatedPkg.id, updatedPkg)
      .then((res) => {
        if (res.status === 200) {
          MySwal.fire({
            icon: 'success',
            title: 'Güncelleme Başarılı',
            text: 'Paket başarıyla güncellendi!',
            confirmButtonText: 'Tamam',
          });
          loadPackages(); // Güncelleme sonrası paketleri yeniden yükle
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Güncelleme Hatası',
            text: 'Güncelleme sırasında bir hata oluştu.',
            confirmButtonText: 'Tamam',
          });
        }
      })
      .catch((error) => {
        console.error('Güncelleme hatası:', error);
        MySwal.fire({
          icon: 'error',
          title: 'Güncelleme Hatası',
          text: 'Beklenmedik bir hata oluştu.',
          confirmButtonText: 'Tamam',
        });
      });
  };

  return (
    <Box>
      {/*
      <Box marginBottom={5}>
        <Typography variant='h5' color='text.tertiary' sx={{ fontWeight: 700, textAlign: 'start' }}>
          Paket bilgileri
        </Typography>
      </Box>
      */}
      {packages.map((pkg) => (
        <Accordion key={pkg.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${pkg.id}-content`}
            id={`panel-${pkg.id}-header`}
          >
            <Typography variant='h6' color='text.primary'>
              {pkg.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexDirection='column' gap={2}>
              <Box display='flex' gap={2}>
                <TextField
                  label={t('price')}
                  value={pkg.price}
                  onChange={(e) => handleChange(pkg.id, 'price', e.target.value)}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label={t('indexMeetingMaxParticipant')}
                  type='number'
                  value={pkg.maxParticipant}
                  onChange={(e) => handleChange(pkg.id, 'maxParticipant', e.target.value)}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label={`${t('indexMeetingDuration')} (${t('indexMinute')})`}
                  type='number'
                  value={pkg.duration}
                  onChange={(e) => handleChange(pkg.id, 'duration', e.target.value)}
                  sx={{ flex: 1 }}
                />
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.createMeetingForLater}
                    onChange={(e) =>
                      handleChange(pkg.id, 'createMeetingForLater', e.target.checked)
                    }
                  />
                }
                label={t('planMeeting')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.recordMeeting}
                    onChange={(e) =>
                      handleChange(pkg.id, 'recordMeeting', e.target.checked)
                    }
                  />
                }
                label={t('servicesVideoWebinar')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.useNotes}
                    onChange={(e) =>
                      handleChange(pkg.id, 'useNotes', e.target.checked)
                    }
                  />
                }
                label={t('meetingNoteCreation')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.useWhiteboard}
                    onChange={(e) =>
                      handleChange(pkg.id, 'useWhiteboard', e.target.checked)
                    }
                  />
                }
                label={t('servicesSmartBoard')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.useScreenShare}
                    onChange={(e) =>
                      handleChange(pkg.id, 'useScreenShare', e.target.checked)
                    }
                  />
                }
                label={t('screenShare')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.useGeneralChat}
                    onChange={(e) =>
                      handleChange(pkg.id, 'useGeneralChat', e.target.checked)
                    }
                  />
                }
                label={t('inChatMeeting')}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={() => handleUpdate(pkg)}
              >
                {t('updatePassword')}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default PackageEdit;
